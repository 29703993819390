export const LOADING_DATA_HEALTH_CHECK = 'LOADING_DATA_HEALTH_CHECK';
export const GET_DATA_HEALTH_CHECK = 'GET_DATA_HEALTH_CHECK';
export const GET_ERRORS = 'GET_ERRORS';

export const SET_SORT_PROFILING_DATA_FILES = 'SET_SORT_PROFILING_DATA_FILES';
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';

export const LOADING_DATA_PROFILING_FILES = 'LOADING_DATA_PROFILING_FILES';
export const GET_DATA_PROFILING_FILES = 'GET_DATA_PROFILING_FILES';
export const SET_SORT_BY_UPDATED_DATE = 'SET_SORT_BY_UPDATED_DATE';
export const SET_SORT_BY_CREATED_DATE = 'SET_SORT_BY_CREATED_DATE';
