export const GET_ERRORS = 'GET_ERRORS';
export const LOADING_ARCHIVES_FILES = 'LOADING_ARCHIVES_FILES';
export const GET_ARCHIVES_FILES = 'GET_ARCHIVES_FILES';
export const SET_SORT = 'SET_SORT';
export const SET_SORT_BY_FILE_NAME = 'SET_SORT_BY_FILE_NAME';
export const SET_SEARCH = 'SET_SEARCH';
// export const SET_SEARCH_ARCHIVE = 'SET_SEARCH_ARCHIVE';
// archives
export const LOADING_ARCHIVES = 'LOADING_ARCHIVES';
export const GET_ARCHIVES = 'GET_ARCHIVES';
export const SET_SORT_ARCHIVES = 'SET_SORT_ARCHIVES';
export const SET_SEARCH_ARCHIVES = 'SET_SEARCH_ARCHIVES';
export const LOADING_ARCHIVE_FILES = 'LOADING_ARCHIVE_FILES';
export const GET_ARCHIVE_FILES = 'GET_ARCHIVE_FILES';

export const SET_SEARCH_ARCHIVE = 'SET_SEARCH_ARCHIVE'; //
export const SET_SEARCH_ARCHIVE_FILES = 'SET_SEARCH_ARCHIVE_FILES';

export const SORT_ARCHIVE_BY_UPDATED_DATE = 'SORT_ARCHIVE_BY_UPDATED_DATE';
export const SORT_ARCHIVE_BY_CREATED_DATE = 'SORT_ARCHIVE_BY_CREATED_DATE';
// files
export const LOADING_FILES = 'LOADING_FILES';
export const GET_FILES = 'GET_FILES';
export const SET_SEARCH_FILES = 'SET_SEARCH_FILES';
export const SORT_FILES_BY_UPDATED_DATE = 'SORT_FILES_BY_UPDATED_DATE';
export const SORT_FILES_BY_CREATED_DATE = 'SORT_FILES_BY_CREATED_DATE';
export const SORT_FILES_BY_FILE_NAME = 'SORT_FILES_BY_FILE_NAME';
export const SORT_FILES_BY_PROJET_ID = 'SORT_FILES_BY_PROJET_ID';
export const SORT_FILES_SUBFOLDER = 'SORT_FILES_SUBFOLDER';
