const navigation = {
  startproject: [],
  archives: [
    {
      title: 'Archive',
      subTitle: '(Moody’s RI Input Files)',
      url: 'archive',
    },
    {
      title: 'Input Files',
      url: 'input-files',
    },
    {
      title: 'Output Files',
      url: 'output-files',
    },
    {
      title: 'Disclosure Reports',
      url: 'gmm-reports',
    },
  ],
  datagovernance: [
    {
      title: 'Data Profiling',
      url: 'data-profiling',
    },
    {
      title: 'DQ Dashboard',
      url: 'quality',
    },
    {
      title: 'Data Lineage',
      url: 'lineage',
    },
  ],
  dashboard: [],
};

export default navigation;
